import React from "react";
import Layout from "../components/layout";
import GoogleFormAlt from "../components/googleformalt";
import SEO from "../components/seo";
import Heroimage from "../components/HeroImageAbout";
//import YouTubeEmbed from "../components/youtube-embed";
import ScrollableAnchor from 'react-scrollable-anchor'
import ReactMarkdown from 'react-markdown'
import {graphql} from "gatsby";


const AboutPage = ({data}) => {
    const aboutPageContent = data.allContentfulAboutPageContent.edges;
    const aboutPageAboutContent = aboutPageContent[0].node.aboutText.aboutText;
    const aboutPagePartnerContent = aboutPageContent[0].node.partnerText.partnerText;

    return (
        <Layout>
            <SEO bodyClass="about-page" title="Partner with us" keywords={[`HIV`, `South Africa`, `HIV counseling`, `HIV test`, `HIV testing`, `Youth Health Counseling`, `eHealth`, `Digital Counseling`, `MSF`, `Aviro`, `HIV counseling`, `AIDS counseling`]}/>
            <div className="hero">
                <Heroimage/>
            </div>
            <div className="container">
                <section className="section about-content">
                    <h1 className="title"><span className="def-word">Khetha</span>
                        <span className="def-type">verb :</span>
                        <span className="def-def">to Choose</span> ; <span className="break-smartphone">origin : <span className="def-origin">Xhosa</span></span>
                    </h1>
                    <div className="pres-paras">
                        <span className="drop-cap-k">&nbsp;</span>
                        <ReactMarkdown source={aboutPageAboutContent}/>
                    </div>
                </section>
                <div id="partner">
                    <section className="section partner-content">
                        <div className="pres-paras">
                        <h2 className="subtitle">Partner With Us</h2>
                        <ReactMarkdown source={aboutPagePartnerContent}/>
                        </div>
                        <GoogleFormAlt/>
                    </section>
                </div>
            </div>
        </Layout>
    )

};

export default AboutPage;
export const query = graphql`
  query AboutPageQuery {
    allContentfulAboutPageContent(limit: 1000) {
        edges {
          node {
            aboutText {
              aboutText
            }
            partnerText {
              partnerText
            }
          }
        }
      }
    }
`;
