import React, { Component } from 'react'
import axios from 'axios'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const GOOGLE_FORM_NAME_ID = 'entry.4151636'
const GOOGLE_FORM_ORGANIZATION_ID = 'entry.800309440'
const GOOGLE_FORM_EMAIL_ID = 'entry.319899461'
const GOOGLE_FORM_PHONE_ID = 'entry.448396966'
const GOOGLE_FORM_USECASE_SENTINAL_ID = 'entry.426983285_sentinel'
const GOOGLE_FORM_USECASE_ENTRY_ID = 'entry.426983285'

//const GOOGLE_FORM_MESSAGE_ID = 'entry.774936702'

const CORS_PROXY = 'https://cors-anywhere.herokuapp.com/'

const GOOGLE_FORM_ACTION = 'https://docs.google.com/forms/u/2/d/e/1FAIpQLScQIYaN8wVp_pFw-_k7SAPzbjzmLXo7FmlgTJqnQOX-J3ZbbA/formResponse'

class ContactBox extends Component {
  constructor(props){
    super(props)
    this.state = {
      name: '',
      organization: '',
      email: '',
      phone: '',
      usecaseSentinal: '',
      usecases: [],
      showForm: true,
      sendingMessage: false,
      messageSent: false,
      messageError: false
    }
  }

  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value})
  }

  handleChangeCheckbox = (event) => {
    // you only want to 'mutate' a temporary array
    let items = this.state.usecases;
    if (event.target.checked) {
      items[event.target.name] = event.target.value;
    } else {
      // you must retain previously set indexes to avoid adding duplicates
      items[event.target.name] = '';
    }
    this.setState({usecases: items});
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.setState({
      sendingMessage: true
    })
    this.sendMessage()
  }

  handleFormToggle = () => {
    this.setState(
      (prevState) => {
        const { showForm } = prevState
        return {
          showForm: !showForm
        }
      }
    )
  }

  handleReturnButton = () => {
    this.setState(
      {
        showForm: true,
        messageSent: false,
        messageError: false
      }
    )
  }

  sendMessage = () => {
    // it maybe be pointless to do these , but this removes empty values
    let items = this.state.usecases.filter(function(n){ return n.length > 0});
    // and this re-indexes the array
    items = items.filter(val => val)

    this.setState({usecases: items});
    const formData = new FormData()
    formData.append(GOOGLE_FORM_NAME_ID, this.state.name)
    formData.append(GOOGLE_FORM_ORGANIZATION_ID, this.state.organization)
    formData.append(GOOGLE_FORM_EMAIL_ID, this.state.email)
    formData.append(GOOGLE_FORM_PHONE_ID, this.state.phone)
    formData.append(GOOGLE_FORM_USECASE_SENTINAL_ID, this.state.usecaseSentinal)
    let index = 0;
    // do NOT use a for loop here, it will break the browser
    while (index < this.state.usecases.length) {
      formData.append(GOOGLE_FORM_USECASE_ENTRY_ID, this.state.usecases[index])
      index++;
    }


    axios.post(CORS_PROXY + GOOGLE_FORM_ACTION,formData)
      .then(() => {
        this.setState({
          messageSent: true,
          sendingMessage: false,
          name: '',
          organization: '',
          email: '',
          phone: '',
          usecaseSentinal: '',
          usecases: []
        })
      }).catch(() => {
        this.setState({
          messageError: true,
          sendingMessage: false
        })
      })
  }

  returnButton = () => <button className="button" id='return-button' onClick={this.handleReturnButton}>Return</button>

  render() {
    if(this.state.sendingMessage){
      return(
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
      )
    }

    if(this.state.messageSent){
      return(
        <React.Fragment>
          <p className='pres success-message'>Thank you for your response.  We will get back to you as soon as possible.</p>
          {this.returnButton()}
        </React.Fragment>
      )
    }

    if(this.state.messageError){
      return(
        <React.Fragment>
          <div className='error-message'>Sorry, your message was not sent. Contact me at: info@avirohealth.com</div>
          {this.returnButton()}
        </React.Fragment>
      )
    }

    if(this.state.showForm === false){
      return(
        <button id='contact-button' className='btn btn-sm' onClick={this.handleFormToggle}>Contact</button>
      )
    }

    return(
      <React.Fragment>
            <form onSubmit={this.handleSubmit}>

              <div className='field'>
                <label htmlFor='name' className='label'>Name:</label>
                <div className='control'>
                  <input
                      type='text'
                      name='name'
                      id='name'
                      className='input'
                      value={this.state.name}
                      onChange={this.handleChange}
                      required
                      placeholder="Your Name"
                  />
                </div>
              </div>

              <div className='field'>
                <label htmlFor='email' className='label'>Email:</label>
                <div className='control'>
                  <input
                      type='email'
                      name='email'
                      id='email'
                      className='input'
                      value={this.state.email}
                      onChange={this.handleChange}
                      required
                      placeholder="e.g. alexsmith@gmail.com"
                  />

                </div>
              </div>

                <div className='field'>
                <label htmlFor='organization' className='label'>Organization:</label>
                <div className='control'>
                  <input
                      type='organization'
                      name='organization'
                      id='organization'
                      className='input'
                      value={this.state.organization}
                      onChange={this.handleChange}
                      required
                      placeholder="e.g. alexsmith@gmail.com"
                  />

                </div>
              </div>

              <div className='field'>
                <label htmlFor='phone' className='label'>Phone:</label>
                <div className='control'>
                  <PhoneInput
                      country="ZA"
                      placeholder="Enter phone number"
                      value={ this.state.phone }
                      onChange={phone => this.setState({ phone })}
                      className="input"
                  />
                </div>
              </div>

              <div className="field-group">
                  <div className="field">
                      <label className="checkbox">
                          <input
                              type='checkbox'
                              //the name must match
                              name='0'
                              id='usecase-1'
                              value="I want to try the app"
                              onChange={this.handleChangeCheckbox}
                          />I want to try the app
                      </label>
                  </div>
                  <div className="field">
                      <label className="checkbox">
                          <input
                              type='checkbox'
                              name='1'
                              id='usecase-2'
                              value="I want to use the media"
                              onChange={this.handleChangeCheckbox}
                          />I want to use the media
                      </label>
                  </div>
                  <div className="field">
                      <label className="checkbox">
                          <input
                              type='checkbox'
                              name='2'
                              id='usecase-3'
                              value="I want to try the app and use the media"
                              onChange={this.handleChangeCheckbox}
                          />I want to try the app and use the media
                      </label>

                  </div>
              </div>

              <div>

                <div className="field">
                  <div className="control">
                    <button type="submit" className="button is-link">Submit</button>
                  </div>
                </div>

              </div>
            </form>

      </React.Fragment>
    )
  }
}

export default ContactBox
